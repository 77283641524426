@import '../../../styles/variables';
@import '../../../styles/helpers';

.AccordionComponent {
  width: 100%;

  .AccordionComponent__Summary {
    flex-direction: row-reverse;
    padding: 0px;
    & .MuiAccordionSummary-expandIcon {
      margin-right: $spacing-extra-small;
    }

    & .MuiAccordionSummary-expandIcon.Mui-expanded {
      transform: rotate(90deg);
    }
  }

  .AccordionComponent__Details {
    display: flex;
    flex-direction: column;
  }
}
