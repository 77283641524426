@import '../../../styles/helpers';

.MonthPickerField {
  width: 100%;
}

.MonthPickerField__RangeValue,
.MonthPickerField__Body .MonthPickerField__Type {
  display: grid;
  grid: auto / repeat(2, 1fr);
  padding: $spacing-small-1q 0;
  border-bottom: 1px solid #ccc;
}

.MonthPickerField__Body .MonthPickerField__Type > * {
  text-align: center;
  margin: 0 0 0 -#{$spacing-medium-1q};
  display: block;
}

.MonthPickerField__RangeValue .MonthPickerField__Value {
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}

.MonthPickerField__ClearButton {
  padding: $spacing-small-1q 0;
  border-top: 1px solid #ccc;
  text-align: center;
}

.MonthPickerField__ClearButton button {
  text-transform: none;
}

.MonthPicker__YearSelector {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
  padding: 0;
}

.MonthPicker__YearSelector > * {
  margin: auto;
}

.MonthPickerField__Popover .MonthPicker__YearSelector select {
  padding-right: 36px;
}

.MonthPickerField .FieldPopover {
  border: 1px solid $mid-gray;
  border-radius: $br-2;
}

.MonthPicker__MonthSelector {
  padding: $spacing-small-1q;
  display: grid;
  grid: repeat(4, 1fr) / repeat(3, 1fr);
  row-gap: $spacing-small-1q;
  column-gap: $spacing-small-1q;
}

@include applyThemes {
  .MonthPicker__MonthSelector .MonthPicker__MonthButton--included {
    background-color: getThemeColor(primary-subtle);
  }

  .MonthPicker__MonthSelector .MonthPicker__MonthButton--selected {
    background-color: getThemeColor(primary);
  }
}

.MonthPickerField__Popover__Icon {
  color: rgba(0, 0, 0, 0.54);
}

.MonthPickerField {
  &.MuiFormControl-root {
    margin-bottom: $spacing-medium-2q;
  }

  .MuiFormLabel-root {
    display: block;
    font-weight: 700;
    line-height: $lh-copy;
    margin-bottom: $spacing-extra-small;
    position: static;
    transform: none;
    font-size: getTypeScale(f6);
  }

  .MuiFormLabel-root:not(.Mui-error) {
    color: $dark-gray;
  }

  legend {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  fieldset {
    top: 0;
  }

  .MuiFormLabel-root.MuiFormLabel-root {
    transform: none;
  }

  .MuiOutlinedInput-input {
    height: auto;
    line-height: $lh-copy;
    padding: $spacing-small $spacing-medium;
  }

  // override autocomplete styles
  .MuiOutlinedInput-input.MuiOutlinedInput-input.MuiOutlinedInput-input {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: 0 0 0 1px $med-dark-gray;
    transition: box-shadow 0.1s ease-out;
  }

  .Mui-focused > .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 1px $blue-dark;
  }

  .Mui-error > .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 0 1px $red;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
  }
}
