@import '../helpers.scss';

/* Clears out some browser default styles for <button>s */
.button-reset {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0;
  background-color: transparent;
  padding: 0;
  transition: background-color 0.1s ease-out, color 0.1s ease-out,
    fill 0.1s ease-out, stroke 0.1s ease-out;
}
/* If using .btn, no need for .button-reset */
.btn {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
  transform: translateZ(0);
  &:disabled,
  &.is-disabled {
    box-shadow: 0 0 0 1px $mid-gray;
    cursor: default;
  }
}

@include applyThemes {
  .btn {
    box-shadow: 0 0 0 1px getThemeColor('primary-dark');

    &:disabled,
    &.is-disabled {
      box-shadow: 0 0 0 1px $mid-gray;
      cursor: default;
    }
  }

  .btn.bg-green {
    box-shadow: 0 0 0 1px $green;
  }

  .btn.btn-b--dark-subtle-yellow {
    box-shadow: 0 0 0 1px #c8af29;
  }
}

.btn.download-button-indicator {
  .mv2-5 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.btn.btn-ws-normal {
  white-space: normal;
}
.btn-b--green {
  box-shadow: 0 0 0 1px $green;
}
.btn-b--red,
* .btn.bg-red {
  box-shadow: 0 0 0 1px $red;
}
