@import '../../../../styles/helpers';

#profile-menu-popper > div {
  margin: -8px 8px auto auto;
}

.ProfileSettingsMenuCard {
  background-color: $white;
  border: 1px solid $mid-gray;
  max-height: 24rem;
  max-width: 12rem;
  width: 24rem;
}

@include applyThemes {
  .ProfileSettingsMenu__Button {
    padding: $spacing-none $spacing-medium;

    svg {
      color: getThemeColor(navbar-fg);
    }

    .fill-white-hover-dark {
      fill: getThemeColor(navbar-fg);
    }

    .stroke-white-hover-dark {
      stroke: getThemeColor(navbar-fg);
    }

    &:hover,
    &:focus {
      color: getThemeColor(navbar-bg);
      background-color: rgba(getThemeColor(navbar-fg), 0.6);

      .fill-white-hover-dark {
        fill: getThemeColor(navbar-bg);
      }

      .stroke-white-hover-dark {
        stroke: getThemeColor(navbar-bg);
      }
    }
  }
}
