@import '../../../styles/helpers';

.ScrollableSelectField {
  display: flex;
  width: 100%;
}

.ScrollableSelectField--warningMessage .ScrollableSelectField__Body {
  width: calc(100% - #{$spacing-large});
}

.ScrollableSelectField--column {
  flex-direction: column;
}

.ScrollableSelectField--row {
  flex-direction: row;
}

.ScrollableSelectField__Select {
  height: $spacing-large-1q;
  width: 100%;
}

.ScrollableSelectField__Search {
  border: none;
  background-color: #fff;
  border-radius: $spacing-extra-small;
}

.ScrollableSelectField__Search {
  margin-bottom: $spacing-extra-small;
}

.ScrollableSelectField__Search .MRets__TextField.MuiFormControl-root {
  margin-bottom: $spacing-extra-small;
}

.ScrollableSelectField__Search .MuiInputBase-adornedEnd {
  padding-right: 9px;
}

.ScrollableSelectField__Search .MuiInputBase-adornedEnd .MuiIconButton-edgeEnd {
  margin-right: -3px;
  padding: $spacing-extra-small-2px;
}

.ScrollableSelectField__Options {
  margin-top: $spacing-extra-small;
}

.ScrollableSelectField__OptionItem label {
  margin-left: $spacing-medium;
  margin-right: $spacing-extra-small;
  margin-bottom: $spacing-small;
  margin-top: $spacing-small;
}

.ScrollableSelectField__OptionItem:hover {
  background-color: $light-gray;
}

.ScrollableSelectField__OptionItem--selected {
  background-color: $light-mid-gray;
}

.ScrollableSelectField__Loading {
  background-color: transparent;
  padding: $spacing-medium-3q 0 $spacing-small-1q;
  text-align: center;
}

.ScrollableSelectField__NextPageLoading {
  background-color: transparent;
  text-align: center;
}

.ScrollableSelectField__NextPageLoading svg {
  height: $spacing-medium-3q;
  margin-bottom: 0;
}

.ScrollableSelectField__EmptyList {
  font-weight: bold;
  margin-bottom: $spacing-small-2q;
  margin-left: $spacing-small-2q;
  margin-top: $spacing-small-2q;
  font-size: getTypeScale(f6);
  color: #2c405a;
}

.ScrollableSelectField__Popover .ScrollableSelectField__Popover__Paper {
  border: 1px solid #e7eaed !important;
  color: #2c405a !important;
  width: $spacing-extra-extra-large-25;
}

.ScrollableSelectField__Popover__Content {
  max-height: 300px;
}

.ScrollableSelectField__Select {
  &.MuiFormControl-root {
    margin-bottom: $spacing-medium-2q;
  }

  .MuiFormLabel-root {
    display: block;
    font-weight: 700;
    line-height: $lh-copy;
    margin-bottom: $spacing-extra-small;
    position: static;
    transform: none;
    font-size: getTypeScale(f6);
  }

  .MuiFormLabel-root:not(.Mui-error) {
    color: $dark-gray;
  }

  .MRets__ScrollableSelectField__WarningMessage {
    position: absolute;
    right: -#{$spacing-small};
    top: 0;
    height: 100%;

    svg {
      margin: auto 0;
      color: $warning;

      path:first-child {
        opacity: 0.1;
      }
    }
  }

  legend {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  fieldset {
    top: 0;
  }

  .MuiFormLabel-root.MuiFormLabel-root {
    overflow: inherit;
    transform: none;
  }

  .MuiOutlinedInput-input {
    height: auto;
    line-height: $lh-copy;
    padding: $spacing-small $spacing-medium;
  }

  // override autocomplete styles
  .MuiOutlinedInput-input.MuiOutlinedInput-input.MuiOutlinedInput-input {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: 0 0 0 1px $med-dark-gray;
    transition: box-shadow 0.1s ease-out;
  }

  .Mui-focused > .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 $spacing-extra-small 1px $blue-dark;
  }

  .Mui-error > .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 0 1px $red;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
  }
}
