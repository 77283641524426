@import '../../../styles/helpers';

.PageTopper {
  border-bottom: 1px solid $light-mid-gray;
  margin-bottom: $spacing-medium;
  margin-left: -#{$spacing-medium};
  margin-right: -#{$spacing-medium};
  margin-top: -#{$spacing-medium};
}

@include applyThemes {
  .PageTopper {
    background-color: getThemeColor(pageheader-bg);
    color: getThemeColor(pageheader-fg);
  }
}
