@import '../../../styles/variables';

.Notes__Header {
  display: flex;
}

.NotesList__List {
  list-style-type: none;
  padding: 0rem;
  margin: 0rem;

  li {
    border-bottom: 1px solid $border-color;
    padding: $spacing-medium;
  }
  li:last-child {
    border-bottom: none;
  }

  .NotesList__Details {
    color: $mid-gray;
    font-size: getTypeScale(f6);
    margin-top: $spacing-small;
  }
}

.NotesList__Add {
  display: flex;
  padding: $spacing-medium;

  .NotesList__Input {
    background-color: $white;
  }

  .NotesList__AddButton {
    display: flex;
    flex-direction: row;
    margin: $spacing-none $spacing-medium;
    height: 90%;
  }
}
