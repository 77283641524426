@import '../../../styles/helpers';

@include applyThemes {
  .MastHead {
    color: getThemeColor(navbar-fg);
    background-color: getThemeColor(navbar-bg);
    border-bottom: 1px solid getThemeColor(navbar-border-color);
  }

  .MastHead .BurgerButton {
    color: getThemeColor(navbar-burger-fg);
    background-color: getThemeColor(navbar-burger-bg);

    &:hover,
    &:focus {
      background-color: rgba(getThemeColor(navbar-burger-fg), 0.7);

      span {
        background-color: $dark-gray;
      }
    }
  }
}
