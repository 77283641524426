@import '../../../styles/helpers';

@include applyThemes {
  .MainNav {
    line-height: 1.5;
    font-weight: 700;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: block;

      li {
        display: block;

        a {
          transition:
            background-color 0.1s ease-out,
            color 0.1s ease-out,
            border-color 0.1s ease-out;
          text-decoration: none;
          padding: 1rem;
          color: $dark-gray;
          display: flex;
          border-left: $bw2 solid transparent;
        }
        a:hover {
          background-color: $mid-gray;

          .layered-path {
            stroke: $mid-gray;
          }
        }
        .active {
          border-color: getThemeColor(secondary);
          color: $white;
          background-color: $med-dark-gray;
          border-color: getThemeColor(secondary);

          .fill-grey-active-white {
            fill: $white;
          }

          .stroke-grey-active-white {
            stroke: $white;
          }

          .layered-path {
            stroke: $med-dark-gray;
          }
        }
      }
    }

    .fill-grey-active-white {
      fill: $dark-med-dark-gray;
    }

    .stroke-grey-active-white {
      stroke: $dark-med-dark-gray;
    }

    .layered-path {
      stroke: $white;
      transition: all 0.1s ease-out;
    }
  }
}
