@import '../../styles/helpers.scss';

.Spinner {
  animation: 3.4s linear infinite spinner-rotate;
}

@include applyThemes {
  .Spinner__Path--primary {
    fill: getThemeColor(primary);
  }

  .Spinner__Path--secondary {
    fill: getThemeColor(secondary);
  }
}

@keyframes spinner-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
