@import '../../../styles/helpers';

.MRetsModal {
  .MuiPaper-root {
    max-width: 760px;
  }
}

// Colors
@include applyThemes {
  .MRetsModal__Title {
    padding-bottom: $spacing-extra-small;

    h2 {
      color: getThemeColor(primary-dark);
      flex-grow: 1;
      font-size: getTypeScale(f2);
      font-weight: 400;
      line-height: 1;
    }
  }
}

.MRetsModal__CloseButton {
  background: none;
  border: none;
  color: $med-dark-gray;
  line-height: 1;
  padding: 0;
  position: absolute;
  top: $spacing-medium;
  right: $spacing-medium;

  &:hover,
  &:focus {
    color: $dark-gray;
  }

  svg {
    display: block;
  }

  path {
    fill: currentColor;
  }
}

.MRetsModal__Actions {
  margin: auto;
  padding: $spacing-medium;

  > button:not(:first-child) {
    margin-left: $spacing-small;
  }
}
