@import '../../../styles/variables/';

.UploadButton__Content {
  padding: $spacing-medium;
  border: $spacing-extra-small-1px solid $dark-gray;
  overflow: hidden;
}

.UploadButton__Button {
  margin-left: $spacing-medium;
  height: $spacing-large;
}

.UploadButton__Filetypes {
  margin-bottom: $spacing-medium;
  font-size: $spacing-small-2q;
}

.UploadButton__Popover {
  margin-top: $spacing-small-2q;
}

.FieldPopover__Popover__Paper.UploadButton__PopoverField {
  width: $spacing-extra-extra-large-20;
}

.UploadButton__FileUpload {
  margin-bottom: $spacing-small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.UploadButton__FileUploadLabel {
  color: $med-dark-gray;
  font-weight: 400;
  margin-bottom: $spacing-small-2q;
}

.UploadButton__TemplatesLink {
  margin-bottom: $spacing-medium;
}

.UploadButton__ButtonsLine {
  border-bottom-style: solid;
  border-bottom-width: $spacing-extra-small-1px;
  border-top-width: 0;
  color: $mid-gray;
  margin-bottom: $spacing-small-2q;
  margin-left: -#{$spacing-small-2q};
  margin-right: -#{$spacing-small-2q};
  margin-top: $spacing-small-2q;
  overflow: visible;
}

.UploadButton__Buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.UploadButton__ErrorSection {
  margin-bottom: $spacing-small-2q;
}

.UploadButton__ExclamationError {
  align-items: center;
  background-color: $subtle-red;
  border-color: $red;
  border-radius: $spacing-extra-small;
  border-style: solid;
  border-width: $spacing-extra-small-1px;
  flex-direction: row;
  display: flex;
  height: $spacing-large-1q;
  justify-content: center;
  margin-bottom: $spacing-medium;
}

.UploadButton__ExclamationErrorMessage {
  color: $dark-gray;
}

.UploadButton__SuccessSection {
  align-items: center;
  background-color: $subtle-green;
  border-color: $green;
  border-radius: $spacing-extra-small;
  border-style: solid;
  border-width: $spacing-extra-small-1px;
  flex-direction: row;
  display: flex;
  padding: $spacing-medium;
}
