.Layout__Info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-right: 1rem;
}

.Info__Version {
  margin-top: 0.5rem;
  text-align: end;
}

.Version__Bold {
  font-weight: bold;
  margin-left: 0.5rem;
}

.Info__Name {
  margin-top: 0.5rem;
  text-align: end;
}

.Name__Bold {
  font-weight: bold;
  margin-left: 0.5rem;
}

.Info__API__Root {
  margin-top: 0.5rem;
  text-align: end;
}

.API__Root__Bold {
  font-weight: bold;
  margin-left: 0.5rem;
}
