// Converted Variables

// Custom Media Query Variables

/*

   SKINS:PSEUDO

   Customize the color of an element when
   it is focused or hovered over.

 */

.hover-black:hover,
.hover-black:focus {
  color: $black;
}
.hover-dark-gray:hover {
  color: $dark-gray;
}
.hover-med-dark-gray:hover,
.hover-med-dark-gray:focus {
  color: $med-dark-gray;
}
.hover-mid-gray:hover,
.hover-mid-gray:focus {
  color: $mid-gray;
}
.hover-light-gray:hover,
.hover-light-gray:focus {
  color: $light-gray;
}
.hover-white:hover,
.hover-white:focus {
  color: $white;
}
.hover-inherit:hover,
.hover-inherit:focus {
  color: inherit;
}

.hover-mrets-blue:hover,
.hover-mrets-blue:focus {
  color: $mrets-blue;
}
.hover-mrets-green:hover,
.hover-mrets-green:focus {
  color: $mrets-green;
}

.hover-chart-dark-green:hover,
.hover-chart-dark-green:focus {
  color: $chart-dark-green;
}
.hover-chart-med-dark-green:hover,
.hover-chart-med-dark-green:focus {
  color: $chart-med-dark-green;
}
.hover-chart-mid-green:hover,
.hover-chart-mid-green:focus {
  color: $chart-mid-green;
}
.hover-chart-light-green:hover,
.hover-chart-light-green:focus {
  color: $chart-light-green;
}

.hover-chart-dark-orange:hover,
.hover-chart-dark-yellow:focus {
  color: $chart-dark-orange;
}
.hover-chart-med-dark-orange:hover,
.hover-chart-med-dark-orange:focus {
  color: $chart-med-dark-orange;
}
.hover-chart-mid-orange:hover,
.hover-chart-mid-orange:focus {
  color: $chart-mid-orange;
}
.hover-chart-light-orange:hover,
.hover-chart-light-orange:focus {
  color: $chart-light-orange;
}

.hover-chart-dark-blue:hover,
.hover-chart-dark-blue:focus {
  color: $chart-dark-blue;
}
.hover-chart-med-dark-blue:hover,
.hover-chart-med-dark-blue:focus {
  color: $chart-med-dark-blue;
}
.hover-chart-mid-blue:hover,
.hover-chart-mid-blue:focus {
  color: $chart-mid-blue;
}
.hover-chart-light-blue:hover,
.hover-chart-light-blue:focus {
  color: $chart-light-blue;
}

.hover-bg-black:hover,
.hover-bg-black:focus {
  background-color: $black;
}
.hover-bg-dark-gray:hover,
.hover-bg-dark-gray:focus {
  background-color: $dark-gray;
}
.hover-bg-med-dark-gray:hover,
.hover-bg-med-dark-gray:focus {
  background-color: $med-dark-gray;
}
.hover-bg-mid-gray:hover {
  background-color: $mid-gray;
}
.hover-bg-light-gray:hover,
.hover-bg-light-gray:focus {
  background-color: $light-gray;
}
.hover-bg-white:hover,
.hover-bg-white:focus {
  background-color: $white;
}
.hover-bg-transparent:hover,
.hover-bg-transparent:focus {
  background-color: $transparent;
}
.hover-red:hover,
.hover-red:focus {
  color: $red;
}
.hover-yellow:hover,
.hover-yellow:focus {
  color: $yellow;
}
.hover-green:hover,
.hover-green:focus {
  color: $green;
}
.hover-blue:hover,
.hover-blue:focus {
  color: $blue;
}
.hover-subtle-blue:hover,
.hover-subtle-blue:focus {
  color: $subtle-blue;
}
.hover-subtle-green:hover,
.hover-subtle-green:focus {
  color: $subtle-green;
}
.hover-subtle-yellow:hover,
.hover-subtle-yellow:focus {
  color: $subtle-yellow;
}
.hover-dark-subtle-yellow:hover,
.hover-dark-subtle-yellow:focus {
  color: $dark-subtle-yellow;
}
.hover-subtle-red:hover,
.hover-subtle-red:focus {
  color: $subtle-red;
}

.hover-bg-red:hover,
.hover-bg-red:focus {
  background-color: $red;
}
.hover-bg-yellow:hover,
.hover-bg-yellow:focus {
  background-color: $yellow;
}
.hover-bg-green:hover,
.hover-bg-green:focus {
  background-color: $green;
}
.hover-bg-blue:hover,
.hover-bg-blue:focus {
  background-color: $blue;
}
.hover-bg-subtle-blue:hover,
.hover-bg-subtle-blue:focus {
  background-color: $subtle-blue;
}
.hover-bg-subtle-green:hover,
.hover-bg-subtle-green:focus {
  background-color: $subtle-green;
}
.hover-bg-subtle-yellow:hover,
.hover-bg-subtle-yellow:focus {
  background-color: $subtle-yellow;
}
.hover-bg-subtle-red:hover,
.hover-bg-subtle-red:focus {
  background-color: $subtle-red;
}
.hover-bg-inherit:hover,
.hover-bg-inherit:focus {
  background-color: inherit;
}

.hover-bg-mrets-blue:hover,
.hover-bg-mrets-blue:focus {
  background-color: $mrets-blue;
}
.hover-bg-mrets-green:hover,
.hover-bg-mrets-green:focus {
  background-color: $mrets-green;
}

.hover-bg-chart-dark-green:hover,
.hover-bg-chart-dark-green:focus {
  background-color: $chart-dark-green;
}
.hover-bg-chart-med-dark-green:hover,
.hover-bg-chart-med-dark-green:focus {
  background-color: $chart-med-dark-green;
}
.hover-bg-chart-mid-green:hover,
.hover-bg-chart-mid-green:focus {
  background-color: $chart-mid-green;
}
.hover-bg-chart-light-green:hover,
.hover-bg-chart-light-green:focus {
  background-color: $chart-light-green;
}

.hover-bg-chart-dark-orange:hover,
.hover-bg-chart-dark-orange:focus {
  background-color: $chart-dark-orange;
}
.hover-bg-chart-med-dark-orange:hover,
.hover-bg-chart-med-dark-orange:focus {
  background-color: $chart-med-dark-orange;
}
.hover-bg-chart-mid-orange:hover,
.hover-bg-chart-mid-orange:focus {
  background-color: $chart-mid-orange;
}
.hover-bg-chart-light-orange:hover,
.hover-bg-chart-light-orange:focus {
  background-color: $chart-light-orange;
}

.hover-bg-chart-dark-blue:hover,
.hover-bg-chart-dark-blue:focus {
  background-color: $chart-dark-blue;
}
.hover-bg-chart-med-dark-blue:hover,
.hover-bg-chart-med-dark-blue:focus {
  background-color: $chart-med-dark-blue;
}
.hover-bg-chart-mid-blue:hover,
.hover-bg-chart-mid-blue:focus {
  background-color: $chart-mid-blue;
}
.hover-bg-chart-light-blue:hover,
.hover-bg-chart-light-blue:focus {
  background-color: $chart-light-blue;
}

@include applyThemes {
  .hover-bg-primary-subtle:hover,
  .hover-bg-primary-subtle:focus {
    background-color: getThemeColor('primary-subtle');
    color: getThemeColor('primary-dark');
  }
}
