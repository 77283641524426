@import '../../../styles/helpers';

.PublicLayout__Logo {
  display: flex;
  margin: $spacing-large auto;
}

.PublicLayout__Logo .Logo {
  margin: 0 auto;
}

.PublicLayout__Footer {
  text-align: center;
  font-size: getTypeScale(f7);
  margin: $spacing-medium 0 $spacing-large;
}

.PublicLayout__Footer a {
  font-weight: bold;
  color: $blue-dark;
  margin-right: $spacing-extra-small-2q;
}
