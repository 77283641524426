@import '../../helpers.scss';

@mixin generate-colors($colors-map) {
  @each $color in color-names($colors-map) {
    .#{$color} {
      color: color($colors-map, $color);
    }
  }
}

@include applyThemes {
  @include generate-colors($theme);
}

@each $ts in map-keys($type-scale) {
  .ts-#{$ts} {
    font-size: map-get($type-scale, $ts);
  }
}
