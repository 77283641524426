@import '../../helpers.scss';

@mixin generate-strokes($colors-map) {
  @each $color in color-names($colors-map) {
    .stroke-#{$color} {
      stroke: color($colors-map, $color);
    }
  }
}

@mixin generate-fills($colors-map) {
  @each $color in color-names($colors-map) {
    .fill-#{$color} {
      fill: color($colors-map, $color);
    }
  }
}

@mixin generate-backgrounds($colors-map) {
  @each $color in color-names($colors-map) {
    .bg-#{$color} {
      background-color: color($colors-map, $color);
    }
  }
}

@include applyThemes {
  .#{$themeType} {
    @include generate-strokes($theme);
    @include generate-fills($theme);
    @include generate-backgrounds($theme);
  }
}
