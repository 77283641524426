@import '../../../styles/helpers';

.MRets__DatePicker {
  width: 100%;

  > label {
    display: block;
    font-weight: 700;
    font-size: getTypeScale(f6);
    line-height: $lh-copy;
    margin-bottom: $spacing-extra-small;
    position: static;
    transform: none;
    white-space: normal;
    text-overflow: none;

    &:not(.Mui-error) {
      color: $dark-gray;
    }
  }

  input {
    border-radius: $br-2;
    border: 1px solid $med-dark-gray;
    font-size: getTypeScale(f6);
    width: 100%;
  }

  input:focus {
    border-color: transparent;
    box-shadow: 0 0 2pt 1.5pt #8eb5e0;
    outline: none;
  }

  .react-datepicker__view-calendar-icon input {
    padding: $spacing-small-1q $spacing-small-1q $spacing-small-1q $spacing-medium;
  }

  &.MuiFormControl-root {
    margin-bottom: $spacing-medium-2q;
  }

  .MuiFormHelperText-root {
    display: none;
  }

  .MRets__TextField input {
    text-transform: uppercase;
  }

  .MRets__DatePicker__WarningMessage {
    position: absolute;
    right: $spacing-medium;
    top: 0;
    height: 100%;

    svg {
      margin: auto 0;
      color: $warning;

      path:first-child {
        opacity: 0.1;
      }
    }
  }
}

.DatePicker--warningMessage > div {
  width: calc(100% - #{$spacing-large});
}

.react-datepicker-popper {
  z-index: 999;
}

.react-datepicker__close-icon {
  padding: 0px;
  margin-right: $spacing-large-1q;
}

.react-datepicker__close-icon::after {
  background-color: $dark-med-dark-gray;
}

@include applyThemes {
  .MRets__DatePicker__Calendar {
    .react-datepicker__day--selected {
      background-color: getThemeColor(primary);
    }
  }
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  right: $spacing-extra-small-1q;
  padding: $spacing-small-1q $spacing-extra-small-1q $spacing-small-1q $spacing-extra-small-1q;
  width: auto;
  height: 100%;
  opacity: 0.4;
}

.MRets__DatePicker__Popper .MRets__DatePicker__Calendar {
  .react-datepicker {
    font-family: $nunito-sans;
  }

  .MuiPickersCalendarHeader-root {
    margin: 0;
    padding-top: $spacing-medium-2q;
    padding-bottom: $spacing-medium-2q;
    border-bottom: 1px solid $mid-gray;
    background-color: rgba($light-mid-gray, 0.6);

    .react-datepicker__navigation {
      border-radius: 100%;
    }

    .react-datepicker__navigation-icon::before {
      border-color: $dark-gray;
    }

    .react-datepicker__header__dropdown select {
      font-weight: 600;
    }

    .react-datepicker__day {
      border-radius: 100%;
    }

    .react-datepicker__day--today {
      border: 1px solid $dark-gray;
      font-weight: normal;
    }

    .react-datepicker__day--outside-month {
      visibility: hidden;
    }

    .react-datepicker__day--selected {
      background-color: getThemeColor(primary);
    }

    .react-datepicker,
    .react-datepicker__month-container {
      width: 100%;
      border-radius: $br-4;
    }

    .react-datepicker__header {
      background-color: transparent;
    }

    .react-datepicker__current-month {
      display: none;
    }
  }
}
