@import '../../../styles/helpers';

.MRets__CheckboxField {
  position: relative;

  .MRets__CheckboxField__WarningMessage {
    position: absolute;
    right: $spacing-medium;
    top: 0;
    height: 100%;

    svg {
      margin: auto 0;
      color: $warning;

      path:first-child {
        opacity: 0.1;
      }
    }
  }
}

.MRets__CheckboxField__Options {
  display: flex;
  flex-direction: column;
}

.MRets__CheckboxField__Options label.MRets__CheckboxField__Option {
  margin: 0 0 0 -9px;
}

.MRets__CheckboxField__Label {
  font-size: getTypeScale(f5);
  line-height: $lh-copy;
  font-weight: 700;
  margin-bottom: $spacing-extra-small;
  color: $dark-gray;
}

.MRets__CheckboxField--error {
  .MRets__CheckboxField__Label {
    color: $red;
  }
}
