@import '../../../styles/helpers';

.MRets__RadioField__Options .MRets__RadioField__Option {
  margin-right: $spacing-medium;
}

.MRets__RadioField__Options .MRets__RadioField__Option:last-child {
  margin-right: 0;
}

@include applyThemes {
  .MRets__RadioField .MRets__RadioField__Label {
    display: flex;
    font-size: getTypeScale(f6);
    color: getThemeColor(neutral-darker);
  }
  .MRets__RadioField .MRets__RadioField__Label a {
    margin: 0 auto;
  }

  .MRets__RadioField.MRets__RadioField--error .MRets__RadioField__Label {
    color: $red;
  }
  .MuiRadio-colorPrimary.Mui-checked:not(.Mui-disabled) {
    color: getThemeColor(primary);
  }
}

.MRets__RadioField__Label .MRets__RadioField__Required {
  margin: 0 $spacing-small-2q 0 $spacing-extra-small;
}

.MRets__RadioField__Required {
  margin-left: $spacing-small;
}

.MRets__RadioField__Label--warningMessage {
  padding-right: $spacing-small;
}

.MRets__RadioField {
  position: relative;
}

.MRets__RadioField .MRets__RadioField__WarningMessage {
  position: absolute;
  right: $spacing-medium;
  top: 0;
  height: 100%;

  svg {
    margin: auto 0;
    color: $warning;

    path:first-child {
      opacity: 0.1;
    }
  }
}
