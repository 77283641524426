@import '../../../../styles/helpers';

.PersistentNotification {
  display: flex;
  background-color: $persistent-notifications-bg;
  padding: 8px 15px;
  border-radius: 4px;

  > div:not(:last-child) {
    margin: auto 8px auto 0;
  }
}

.PersistentNotification .PersistentNotification__Icon {
  width: 28px;
  height: 28px;
  font-size: 28px;

  svg {
    margin: auto;
    color: $persistent-notifications-cta-bg;
  }
}

.PersistentNotification .PersistentNotification__Message {
  font-size: 15px;
  font-weight: 600;
  margin: auto 0;
}

.PersistentNotification .PersistentNotification__CTAButton {
  margin-left: auto;
}

.PersistentNotification .PersistentNotification__CTAButton a {
  background-color: $persistent-notifications-cta-bg;
  color: $white;
}
