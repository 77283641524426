@import '../../../styles/helpers';

.PageHeader {
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
}

@include applyThemes {
  .PageHeader {
    background-color: getThemeColor(pageheader-bg);
    color: getThemeColor(pageheader-fg);
  }
}

.PageHeader .PageHeader__Title {
  margin-top: auto;
  margin-bottom: auto;
}

.PageHeader .PageHeader__Children {
  margin: auto 0 auto auto;
}
