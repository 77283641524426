@import '../../../styles/helpers';

.TooltipLabel {
  display: inline-flex;
}

.TooltipLabel__Label {
  margin-right: $spacing-extra-small;
}

.TooltipLabel .TooltipLabel__Tooltip {
  font-size: getTypeScale(f4);
  color: $mid-gray;
}

.TooltipLabel__Popper .MuiTooltip-tooltip {
  background-color: rgba($black, 0.85);
}

.TooltipLabel__Popper .MuiTooltip-tooltip {
  padding: $spacing-small;
  font-size: getTypeScale(tooltip);
  color: $white;
}

.TooltipLabel__Tooltip__Content a {
  color: $blue-lighter;
  text-decoration: underline;
}
