// Converted Variables

// Custom Media Query Variables

/*

   WIDTHS
   Docs: http://tachyons.io/docs/layout/widths/

   Base:
     w = width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale

     -10  = literal value 10%
     -20  = literal value 20%
     -25  = literal value 25%
     -30  = literal value 30%
     -33  = literal value 33%
     -34  = literal value 34%
     -40  = literal value 40%
     -50  = literal value 50%
     -60  = literal value 60%
     -70  = literal value 70%
     -75  = literal value 75%
     -80  = literal value 80%
     -90  = literal value 90%
     -100 = literal value 100%

     -third      = 100% / 3 (Not supported in opera mini or IE8)
     -two-thirds = 100% / 1.5 (Not supported in opera mini or IE8)
     -auto       = string value auto


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

/* Width Scale */

.w025 {
  width: 0.25rem;
}
.w1 {
  width: 1rem;
}
.w1-25 {
  width: 1.25rem;
}
.w2 {
  width: 2rem;
}
.w2-5 {
  width: 3rem;
}
.w3 {
  width: 4rem;
}
.w3_5 {
  width: 6rem;
}
.w4 {
  width: 8rem;
}
.w4_25 {
  width: 10rem;
}
.w4_5 {
  width: 13rem;
}
.w5 {
  width: 16rem;
}
.w5_10 {
  width: 17rem;
}
.w5_25 {
  width: 20rem;
}
.w5_5 {
  width: 24rem;
}
.w6 {
  width: 32rem;
}

.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-33 {
  width: 33%;
}
.w-34 {
  width: 34%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

.w-third {
  width: calc(100% / 3);
}
.w-two-thirds {
  width: calc(100% / 1.5);
}
.w-auto {
  width: auto;
}

@media #{$breakpoint-not-small} {
  .w1-ns {
    width: 1rem;
  }
  .w2-ns {
    width: 2rem;
  }
  .w3-ns {
    width: 4rem;
  }
  .w4-ns {
    width: 8rem;
  }
  .w5-ns {
    width: 16rem;
  }
  .w-10-ns {
    width: 10%;
  }
  .w-20-ns {
    width: 20%;
  }
  .w-25-ns {
    width: 25%;
  }
  .w-30-ns {
    width: 30%;
  }
  .w-33-ns {
    width: 33%;
  }
  .w-34-ns {
    width: 34%;
  }
  .w-40-ns {
    width: 40%;
  }
  .w-50-ns {
    width: 50%;
  }
  .w-60-ns {
    width: 60%;
  }
  .w-70-ns {
    width: 70%;
  }
  .w-75-ns {
    width: 75%;
  }
  .w-80-ns {
    width: 80%;
  }
  .w-90-ns {
    width: 90%;
  }
  .w-100-ns {
    width: 100%;
  }
  .w-third-ns {
    width: calc(100% / 3);
  }
  .w-two-thirds-ns {
    width: calc(100% / 1.5);
  }
  .w-auto-ns {
    width: auto;
  }
}

@media #{$breakpoint-medium} {
  .w1-m {
    width: 1rem;
  }
  .w2-m {
    width: 2rem;
  }
  .w3-m {
    width: 4rem;
  }
  .w4-m {
    width: 8rem;
  }
  .w5-m {
    width: 16rem;
  }
  .w-10-m {
    width: 10%;
  }
  .w-20-m {
    width: 20%;
  }
  .w-25-m {
    width: 25%;
  }
  .w-30-m {
    width: 30%;
  }
  .w-33-m {
    width: 33%;
  }
  .w-34-m {
    width: 34%;
  }
  .w-40-m {
    width: 40%;
  }
  .w-50-m {
    width: 50%;
  }
  .w-60-m {
    width: 60%;
  }
  .w-70-m {
    width: 70%;
  }
  .w-75-m {
    width: 75%;
  }
  .w-80-m {
    width: 80%;
  }
  .w-90-m {
    width: 90%;
  }
  .w-100-m {
    width: 100%;
  }
  .w-third-m {
    width: calc(100% / 3);
  }
  .w-two-thirds-m {
    width: calc(100% / 1.5);
  }
  .w-auto-m {
    width: auto;
  }
}

@media #{$breakpoint-large} {
  .w1-l {
    width: 1rem;
  }
  .w2-l {
    width: 2rem;
  }
  .w3-l {
    width: 4rem;
  }
  .w4-l {
    width: 8rem;
  }
  .w5-l {
    width: 16rem;
  }
  .w-10-l {
    width: 10%;
  }
  .w-20-l {
    width: 20%;
  }
  .w-25-l {
    width: 25%;
  }
  .w-30-l {
    width: 30%;
  }
  .w-33-l {
    width: 33%;
  }
  .w-34-l {
    width: 34%;
  }
  .w-40-l {
    width: 40%;
  }
  .w-50-l {
    width: 50%;
  }
  .w-60-l {
    width: 60%;
  }
  .w-70-l {
    width: 70%;
  }
  .w-75-l {
    width: 75%;
  }
  .w-80-l {
    width: 80%;
  }
  .w-90-l {
    width: 90%;
  }
  .w-100-l {
    width: 100%;
  }
  .w-third-l {
    width: calc(100% / 3);
  }
  .w-two-thirds-l {
    width: calc(100% / 1.5);
  }
  .w-auto-l {
    width: auto;
  }
}
