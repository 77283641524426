@import '../../../styles/variables';

$border-color: rgba(0, 0, 0, 0.12);

.ListBox__Content {
  background-color: $white;
  border: 1px solid $border-color;
  color: $dark-gray;
}

.ListBox--default.ListBox--no-title {
  border-top: 0;
}

.ListBox__Header {
  display: flex;
  padding: $spacing-medium;
  padding-left: 0;
  color: $dark-gray;
}

.ListBox__Header__Title {
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  font-weight: 800;
}

.ListBox__Header__Actions {
  margin-left: auto;
}

.ListBox__ContentList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ListBox__ContentList__Item {
  display: grid;
  grid: auto / repeat(2, 1fr);
  padding: $spacing-small-2q $spacing-medium;
  border-top: 1px solid $border-color;
}

.ListBox__Item__Label {
  font-weight: bold;
}

.ListBox__Item__Label__Color {
  width: $spacing-small-1q;
  height: $spacing-small-1q;
  display: inline-block;
  margin-right: $spacing-small;
}
