.LoadingModal__Loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 15rem;
}

.LoadingModal__LoadingText {
  font-style: italic;
}
