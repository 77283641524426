@import '../../../styles/variables';
@import '../../../styles/helpers';

.SubNav {
  display: flex;
}

.SubNav__Children {
  margin: auto 0 auto auto;
}

.SubNav__List {
  padding: $spacing-none;
  margin: $spacing-none;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: $dark-gray;
  overflow-x: auto;
}

@include applyThemes {
  .SubNav__List {
    color: getThemeColor(pageheader-fg);

    .active,
    .SubNav__ListItem--active > * {
      border-color: getThemeColor(secondary);
      font-weight: 700;
    }

    .active:hover,
    .SubNav__ListItem--active > * {
      border-color: getThemeColor(secondary);
    }
  }
}

.SubNav .SubNav__Tab {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  height: $spacing-large-2q;
  border-color: $transparent;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-width: $bw2;
  padding-left: $spacing-medium-3q;
  padding-right: $spacing-medium-3q;
  text-decoration: none;
}

.SubNav .SubNav__Tab:hover {
  border-color: $mid-gray;
}

.SubNav__Tab__Icon {
  position: absolute;
  top: 55%;
  left: $spacing-extra-small-1q;
  transform: translateY(-50%);
}

.SubNav__Tab__Icon svg {
  font-size: 110%;
}

.SubNav__List .SubNav__LisItem {
  &.SubNav__LisItem--error {
    .active {
      border-color: $red;
      font-weight: 700;
    }

    .active:hover {
      border-color: $red;
    }

    .SubNav__Tab__Icon {
      color: $red;
    }
  }

  &.SubNav__LisItem--success {
    .SubNav__Tab__Icon {
      color: $green-dark;
    }
  }

  &.SubNav__LisItem--progress {
    .active {
      border-color: $blue-dark;
      border-bottom-style: ridge;
      font-weight: 700;
    }

    .active:hover {
      border-color: $blue-dark;
    }

    .SubNav__Tab__Icon {
      color: $blue-darker;
    }
  }
}
