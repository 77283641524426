@import '../../../styles/helpers';

.MRets__ImageField {
  min-height: $spacing-large-1q;

  &.MuiFormControl-root {
    margin-bottom: $spacing-medium-2q;
  }

  .MuiFormLabel-root {
    display: block;
    font-weight: 700;
    line-height: $lh-copy;
    margin-bottom: $spacing-extra-small;
    position: static;
    transform: none;
    font-size: getTypeScale(f6);
  }

  .MuiFormLabel-root:not(.Mui-error) {
    color: $dark-gray;
  }

  .MuiFormLabel-root.MuiFormLabel-root {
    transform: none;
  }

  input {
    margin: auto 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MRets__ImageField__ImageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .MRets__ImageField__ImageContainer img {
    margin: 0 auto $spacing-medium;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .MRets__ImageField__ImageContainer button {
    position: absolute;
    top: $spacing-medium;
    right: $spacing-medium;
    left: auto;
    bottom: auto;
    background: rgba($white, 0.7);
    border: 1px solid $white;
  }

  .MRets__ImageField__ImageContainer button:hover {
    background: $white;
    border: 1px solid $light-mid-gray;
  }
}
