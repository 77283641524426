// Converted Variables

$link-color: #059cda !default;
$black: #17263a !default;
$dark-gray: #2c405a !default;
$dark-med-dark-gray: #5d7390 !default;
$med-dark-gray: #8c9eb6 !default;
$mid-gray: #bfc8d4 !default;
$light-mid-gray: #e7eaed !default;
$light-gray: #f5f7f8 !default;
$white: #fff !default;
$warning: #ea5c34 !default;
$helper-text: rgba(0, 0, 0, 0.6);

$transparent: transparent !default;

$border-color: rgba(0, 0, 0, 0.12);

// BLUES
$blue-lightest: #b9dafe;
$blue-lighter: #77b2f4;
$blue-light: #569ae7;
$blue-dark: #4283cb;
$blue-darker: #326eb1;
$blue-darkest: #165294;

// GREENS
$green-lightest: #aae7c7;
$green-lighter: #71d7a2;
$green-light: #53bc86;
$green-dark: #3ea770;
$green-darker: #2c9f63;
$green-darkest: #198c50;

// ORANGES
$orange-lightest: #ffeabf;
$orange-lighter: #ffcc66;
$orange-light: #ffaa00;
$orange-dark: #fd8905;
$orange-darker: #e8770e;
$orange-darkest: #db6b04;

// SECONDARY ORANGES (this is necessary because we only have orange hues for RTC)
$orange2-lightest: #ffeabf;
$orange2-darker: #db6b04;

$red: #dc3545 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$blue: #4283cb !default;
$subtle-blue: #b9dafe !default;
$subtle-green: #c1e3ca !default;
$subtle-yellow: #f8e6af !default;
$dark-subtle-red: #a00202 !default;
$dark-subtle-yellow: #da9d0f !default;
$subtle-red: #f6ccd0 !default;

// Brand
$mrets-blue: $blue-lighter;
$mrets-green: $green-lighter;

// Chart/Data Colors
$chart-light-green: $green-lighter;
$chart-mid-green: $green-light;
$chart-med-dark-green: $green-darker;
$chart-dark-green: $green-darkest;

$chart-light-orange: $orange2-lightest;
$chart-mid-orange: $orange-light;
$chart-med-dark-orange: $orange-darker;
$chart-dark-orange: $orange-darkest;

$chart-light-blue: $blue-lighter;
$chart-mid-blue: $blue-light;
$chart-med-dark-blue: $blue-darker;
$chart-dark-blue: $blue-darkest;

// Persisten Notifications
$persistent-notifications-bg: $subtle-red;
$persistent-notifications-cta-bg: $red;
