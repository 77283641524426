@import '../../../styles/variables';

.SearchList {
  padding-bottom: $spacing-medium;
}

.SearchList__Loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: $spacing-medium-2q;
  width: 100%;
}

.SearchList__NextPageLoading {
  background-color: transparent;
  text-align: center;
}

.SearchList__NextPageLoading svg {
  height: $spacing-medium-3q;
  margin-bottom: 0;
}

.SearchList__NoResults {
  padding: $spacing-medium;
  font-weight: 600;
  text-transform: capitalize;
  font-style: italic;
  text-align: center;
}
