.FlashNotification .FlashNotification__Dismiss {
  color: white;
  opacity: 0.5;
}

.FlashNotification:hover .FlashNotification__Dismiss,
.FlashNotification .FlashNotification__Dismiss:focus {
  opacity: 1;
}

.FlashNotification .FlashNotification__Dismiss svg {
  width: 20px;
  height: 20px;
}
