// Converted Variables

// use pixel measurements for anything under 2 px
$spacing-none: 0 !default; // 0
$spacing-extra-small-1px: 0.063rem !default; // 1px
$spacing-extra-small-1_5px: 0.094rem !default; // 1.5px
$spacing-extra-small-2px: 0.125rem !default; // 2px

// extra-small = .25-.4375 rem
$spacing-extra-small: 0.25rem !default; // 4px
$spacing-extra-small-1q: calc(#{$spacing-extra-small} * 1.25); // 0.3125rem; 5px
$spacing-extra-small-2q: calc(#{$spacing-extra-small} * 1.5); // 0.375rem; 6px
$spacing-extra-small-3q: calc(#{$spacing-extra-small} * 1.75); // 0.4375rem; 7px

// small = .5 - .875 rem
$spacing-small: 0.5rem !default; // 8px
$spacing-small-1q: calc(#{$spacing-small} * 1.25); // 0.625rem; 10px
$spacing-small-2q: calc(#{$spacing-small} * 1.5); // 0.75rem; 12px
$spacing-small-3q: calc(#{$spacing-small} * 1.75); // 0.875rem; 14px

// medium = 1 - 1.75 rem
$spacing-medium: 1rem !default; // 16px
$spacing-medium-1q: calc(#{$spacing-medium} * 1.25); // 1.25rem; 20px
$spacing-medium-2q: calc(#{$spacing-medium} * 1.5); // 1.50rem; 24px
$spacing-medium-3q: calc(#{$spacing-medium} * 1.75); // 1.75rem; 28px

// large = 2 - 3.5 rem
$spacing-large: 2rem !default; // 32px
$spacing-large-1q: calc(#{$spacing-large} * 1.25); // 2.5rem; 40px
$spacing-large-2q: calc(#{$spacing-large} * 1.5); // 3rem; 48px
$spacing-large-3q: calc(#{$spacing-large} * 1.75); // 3.5rem; 56px

// extra-large = 4 - 7 rem
$spacing-extra-large: 4rem !default; // 64px
$spacing-extra-large-1q: calc(#{$spacing-extra-large} * 1.25); // 5rem; 80px
$spacing-extra-large-2q: calc(#{$spacing-extra-large} * 1.5); // 6rem; 96px
$spacing-extra-large-3q: calc(#{$spacing-extra-large} * 1.75); // 7rem; 112px

// extra-extra-large = 8+ rem
// append rem value to end
$spacing-extra-extra-large-8: 8rem !default; // 128px
$spacing-extra-extra-large-10: 10rem !default; // 160px
$spacing-extra-extra-large-12: 12rem !default; // 192px
$spacing-extra-extra-large-16: 16rem !default; // 256px
$spacing-extra-extra-large-20: 20rem !default; // 320px
$spacing-extra-extra-large-25: 25rem !default; // 400px
$spacing-extra-extra-large-30: 30rem !default; // 480px
$spacing-extra-extra-large-40: 40rem !default; // 640px
$spacing-extra-extra-large-45: 45rem !default; // 720px
$spacing-extra-extra-large-65: 65rem !default; // 1040px
