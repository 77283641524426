@import '../../../styles/variables';

.FieldPopover {
  border: none;
}

.FieldPopover .FieldPopover__Button {
  height: $spacing-large-1q;
  padding-left: $spacing-extra-small-2q;
  padding-right: $spacing-extra-small-2q;
  width: 100%;

  .FieldPopover__Button .FieldPopover__Icon {
    display: flex;
    margin-left: auto;
  }

  .FieldPopover__ButtonLabel {
    color: rgba(0, 0, 0, 0.4);
    font-size: 1rem;
    font-weight: 400;
    font-family: $sans-serif;
    margin-left: 0$spacing-small;
    overflow: hidden;
    text-transform: none;
    text-overflow: ellipsis;
    text-align: left;
    width: 100%;
    white-space: nowrap;
  }

  .FieldPopover__ButtonLabel--selected {
    color: $black;
  }
}

.FieldPopover__Popover__Paper {
  width: $spacing-extra-extra-large-16;
}
