@import '../../../styles/variables';
@import '../../../styles/helpers';

a.MretsButton,
button.MretsButton {
  border: 0;
  border-radius: $br-1;
  cursor: pointer;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  transform: translateZ(0);
  transition:
    background-color 0.1s ease-out,
    color 0.1s ease-out;
  padding: $spacing-small $spacing-medium;
  font-size: getTypeScale(f7);
  height: fit-content;
  line-height: normal;
}

.MretsButton.MretsButton:disabled,
.MretsButton.MretsButton--is-disabled {
  background-color: $light-mid-gray !important;
  color: $dark-med-dark-gray !important;
  cursor: none;
  opacity: 0.65;
}

// Colors
@include applyThemes {
  .MretsButton.MretsButton--secondary {
    background-color: getThemeColor(button-bg-secondary);
    color: getThemeColor(button-fg-secondary);
    border-color: getThemeColor(button-fg-secondary);
    box-shadow: 0 0 0 1px getThemeColor(button-fg-secondary, $white);

    &:hover,
    &:focus {
      background-color: $dark-gray;
      color: $white;
    }
  }

  .MretsButton.MretsButton--primary {
    background-color: getThemeColor(button-bg-primary);
    color: getThemeColor(button-fg-primary);

    &:hover,
    &:focus {
      background-color: getThemeColor(button-bg-primary);
    }
  }

  .MretsButton.MretsButton--outlined {
    background-color: transparent;
    box-shadow: 0 0 0 1px $light-mid-gray;
    color: $black;

    &:hover,
    &:focus {
      background-color: $light-gray;
      color: getThemeColor(primary-dark);
    }
  }
}

.MretsButton.MretsButton--success {
  background-color: $green;
  box-shadow: 0 0 0 1px $green;
  color: $white;

  &:hover,
  &:focus {
    background-color: $subtle-green;
    color: $green;
  }
}
.MretsButton.MretsButton--danger {
  background-color: $red;
  color: $white;

  &:hover,
  &:focus {
    background-color: $subtle-red;
    color: $red;
  }
}

// Sizes
.MretsButton.MretsButton--small {
  font-size: getTypeScale(f7);
  line-height: 1;
  min-width: $spacing-large;
  padding: $spacing-extra-small $spacing-medium;
}
.MretsButton.MretsButton--medium {
  font-size: getTypeScale(f7);
  line-height: 1;
  min-width: $spacing-large-3q;
  padding: $spacing-small $spacing-large;
}
.MretsButton.MretsButton--large {
  font-size: getTypeScale(f5);
  line-height: $lh-title;
  min-width: $spacing-extra-large-2q;
  padding: $spacing-medium $spacing-large;
}
