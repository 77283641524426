@import '../../../../styles/helpers';

.OrganizationsMenuRoot {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: inherit;
  display: flex;
  font-size: getTypeScale(f5);
  font-weight: 400;
  height: 100%;
  line-height: 1.5;
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
  transition:
    background-color 0.1s ease-out,
    color 0.1s ease-out;
  vertical-align: middle;
  white-space: nowrap;

  .stroke-white-hover-dark {
    stroke: $white;
  }
}

.OrganizationsMenuRoot--multipleOrgs {
  cursor: pointer;

  &:hover {
    background-color: $mid-gray;
    color: $dark-gray;

    .stroke-white-hover-dark {
      stroke: $dark-gray;
    }
  }
}

.OrganizationMenuPopper {
  z-index: 4;
}

.OrganizationMenuCard {
  background-color: $white;
  border: 1px solid $mid-gray;
  max-height: 24rem;
  padding: $spacing-extra-small;
  width: 24rem;
}

.OrganizationMenuSearchLabel {
  display: block;
  margin-bottom: 0;
}

.OrganizationMenuSearchInput {
  font-size: getTypeScale(f7);
  border: 1px solid $mid-gray;
  line-height: $lh-title;
  min-width: 8rem;
  padding: $spacing-small;
  width: 100%;
}

.OrganizationMenuList {
  color: $dark-gray;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}

.OrganizationMenuListItem {
  margin-left: -#{$spacing-extra-small};
  margin-right: -#{$spacing-extra-small};
}

.OrganizationMenuLabel {
  display: flex;
  font-size: getTypeScale(f7);
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  padding: $spacing-small $spacing-medium;
  &:hover {
    background-color: $mid-gray;
  }
}

.OrganizationMenuInput {
  margin-right: $spacing-small;
}

.OrganizationsMenu__Options {
  overflow: hidden;
  max-height: 300px;
}

.OrganizationsMenu__Options .OrganizationMenuOptions__List {
  margin-top: $spacing-extra-small-2px;
  overflow-y: auto;
  overflow-x: hidden;
}

.OrganizationMenuOptions__ListItem__OrganizationName {
  margin-right: auto;
}

.OrganizationsMenu__EndMessage {
  text-align: center;
  margin-top: $spacing-medium;
}

.OrganizationsMenu__NextPageLoading {
  background-color: transparent;
  text-align: center;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.OrganizationsMenu__NextPageLoading svg {
  height: $spacing-medium-3q;
  margin-bottom: 0;
}

.OrganizationsMenu__Options
  .OrganizationMenuOptions__List
  .OrganizationMenuOptions__ListItem__Chip {
  height: 100%;
  padding-top: $spacing-extra-small-2px;
  padding-bottom: $spacing-extra-small-2px;
  text-transform: uppercase;
  font-size: getTypeScale(f7) * 0.6;
  font-weight: bold;
  background-color: white;
  opacity: 0.7;
  margin: auto $spacing-none auto $spacing-small-2q;
}

.OrganizationsMenu__Options .Spinner {
  padding: $spacing-medium;
  height: $spacing-extra-large-2q;
  width: $spacing-extra-large-2q;
  margin: auto;
  display: flex;

  > svg {
    margin: auto;
    padding: $spacing-none;
    width: 100%;
    height: 100%;
  }
}

.OrganizationMenuOptions__ListItem:focus
  .OrganizationMenuOptions__ListItem__Chip,
.OrganizationMenuOptions__ListItem:hover
  .OrganizationMenuOptions__ListItem__Chip {
  opacity: 1;
}

// mrets, wregis, ...
@each $region in map-keys($themes) {
  $regionThemes: map-get($themes, $region);

  // rec, rtc, ...
  @each $themeName in map-keys($regionThemes) {
    $themeTypes: map-get($regionThemes, $themeName);

    // light, dark
    @each $type in map-keys($themeTypes) {
      $theme: map-get($themeTypes, $type);

      .#{$region}.#{$type}
        .OrganizationsMenu__Options
        .OrganizationMenuOptions__List
        .OrganizationMenuOptions__ListItem__Chip--#{$themeName} {
        border: 1px solid getColorFromTheme($theme, secondary);
        color: getColorFromTheme($theme, secondary-dark);
      }

      .#{$region}.#{$type}
        .OrganizationsMenu__Options
        .OrganizationMenuOptions__List
        .OrganizationMenuOptions__ListItem__Chip--qre {
        border: 1px solid getColorFromTheme($theme, neutral-darker);
        color: getColorFromTheme($theme, neutral-darker);
      }
    }
  }
}

@include applyThemes {
  .OrganizationsMenu {
    svg {
      color: getThemeColor(navbar-fg);
    }

    .fill-white-hover-dark {
      fill: getThemeColor(navbar-fg);
    }

    .stroke-white-hover-dark {
      stroke: getThemeColor(navbar-fg);
    }
  }
}
