@import '../../../styles/helpers';

.MRets__SelectField {
  &.MuiFormControl-root {
    margin-bottom: $spacing-medium-2q;
  }

  .MRets__SelectField__WarningMessage {
    position: absolute;
    right: $spacing-medium;
    top: 0;
    height: 100%;

    svg {
      margin: auto 0;
      color: $warning;

      path:first-child {
        opacity: 0.1;
      }
    }
  }

  .MuiFormLabel-root {
    display: block;
    font-weight: 700;
    line-height: $lh-copy;
    margin-bottom: $spacing-extra-small;
    position: static;
    transform: none;
    font-size: getTypeScale(f6);
  }

  .css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-input {
    padding: $spacing-small $spacing-medium;
  }

  .MuiNativeSelect-select {
    background-color: $white;
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
    padding-left: $spacing-medium;
  }

  .MuiInputBase-root.Mui-focused.css-1qgyy9t-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    border-radius: $br-1;
    box-shadow: 0 0 2pt 1.5pt #8eb5e0;

    .MuiNativeSelect-select {
      border: 1px solid transparent;
    }
  }

  .css-1qgyy9t-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    &:before {
      border-bottom: none;
      transition: none;
    }

    &:hover {
      &:not(.Mui-disabled, .Mui-error)::before {
        border-bottom: none;
      }
    }

    &:after {
      border: none;
    }
  }

  .MuiFormLabel-root:not(.Mui-error) {
    color: $dark-gray;
  }

  legend {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  fieldset {
    top: 0;
  }

  .MuiFormLabel-root.MuiFormLabel-root {
    transform: none;
  }

  .MuiOutlinedInput-input {
    height: auto;
    line-height: $lh-copy;
    padding: $spacing-small $spacing-medium;
  }

  // override autocomplete styles
  .MuiOutlinedInput-input.MuiOutlinedInput-input.MuiOutlinedInput-input {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: 0 0 0 1px $med-dark-gray;
    transition: box-shadow 0.1s ease-out;
  }

  .Mui-focused > .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 $spacing-extra-small 1px $blue-dark;
  }

  .Mui-error > .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 0 1px $red;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
  }

  .MRets__SelectField__Label {
    display: flex;
    white-space: normal;
  }

  .MRets__SelectField__Required {
    margin-left: $spacing-small;
  }

  .MRets__SelectField__Label--warningMessage {
    padding-right: $spacing-small;

    .TooltipLabel {
      margin-left: auto;
    }
  }
}

.MRets__SelectField--warningMessage {
  position: relative;

  > div {
    width: calc(100% - #{$spacing-medium * 2});
  }
}
