@import '../../../styles/variables';

.SelectedFile__Label {
  font-weight: bold;
  margin-bottom: $spacing-medium;
}

.File__Name {
  background-color: $light-mid-gray;
  border-radius: $spacing-extra-small;
  padding: $spacing-small;
}

.File__RemoveIcon {
  color: $red;
}

.File__RemoveText {
  color: $red;
}
