@import '../../../styles/helpers';

.BurgerButton {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 4rem;
  justify-content: space-around;
  padding: 0;
  width: 4rem;
  z-index: 10;
  transition: all 0.2s linear;

  &:hover,
  &:focus {
    background-color: $mid-gray;
    & > * {
      background-color: $dark-gray;
    }
  }

  > * {
    transition: all 0.2s linear;
    background-color: $white;
    height: 2px;
    left: 1.5rem;
    position: absolute;
    width: 1rem;

    &:first-child {
      top: 1.625rem;
    }

    &:nth-child(2) {
      top: 1.9375rem;
    }

    &:nth-child(3) {
      bottom: 1.625rem;
    }
  }
}

.BurgerButton.BurgerButton--open {
  > * {
    &:first-child {
      transform: rotatez(-45deg) translate(-3.5px, 3.5px);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotatez(45deg) translate(-3.5px, -3.5px);
    }
  }
}
