@import '../../../styles/helpers';

.MultipleSelectField {
  display: flex;
  width: 100%;
}

.MultipleSelectField--column {
  flex-direction: column;
}

.MultipleSelectField--row {
  flex-direction: row;
}

.MultipleSelectField__Option {
  display: flex;
  flex-direction: row;
  font-size: $spacing-medium;
}

.Option__Text {
  margin-top: $spacing-extra-small;
}

.MultipleSelectField__Select {
  height: $spacing-large-1q;
  width: 100%;
}

.MultipleSelectField__SelectedOptions {
  margin-top: $spacing-medium-1q;
  margin-left: $spacing-small-2q;
  width: 60%;
}

.MultipleSelectField__HelpText {
  margin-top: calc(-1 * $spacing-medium-1q + 2px);
  font-size: getTypeScale(f7);
  color: $helper-text;
}

.MultipleSelectField__Select {
  &.MuiFormControl-root {
    margin-bottom: $spacing-medium-2q;
  }

  .MuiFormLabel-root {
    display: block;
    font-weight: 700;
    line-height: $lh-copy;
    margin-bottom: $spacing-extra-small;
    position: static;
    transform: none;
    font-size: getTypeScale(f6);
  }

  .MuiFormLabel-root:not(.Mui-error) {
    color: $dark-gray;
  }

  legend {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  fieldset {
    top: 0;
  }

  .MuiFormLabel-root.MuiFormLabel-root {
    transform: none;
    overflow: inherit;
  }

  .MuiOutlinedInput-input {
    height: auto;
    line-height: $lh-copy;
    padding: $spacing-small $spacing-medium;
  }

  // override autocomplete styles
  .MuiOutlinedInput-input.MuiOutlinedInput-input.MuiOutlinedInput-input {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: 0 0 0 1px $med-dark-gray;
    transition: box-shadow 0.1s ease-out;
  }

  .Mui-focused > .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 1px $blue-dark;
  }

  .Mui-error > .MuiOutlinedInput-notchedOutline {
    box-shadow: 0 0 0 1px $red;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
  }
}
