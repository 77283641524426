@import '../../../styles/helpers';

.MRets__RepeatableField {
  &.MuiFormControl-root {
    margin-bottom: $spacing-medium-2q;
  }

  .MuiFormLabel-root {
    display: block;
    font-weight: 700;
    line-height: $lh-copy;
    margin-bottom: $spacing-extra-small;
    position: static;
    transform: none;
    font-size: getTypeScale(f6);
  }

  .MuiFormLabel-root:not(.Mui-error) {
    color: $dark-gray;
  }

  .MRets__RepeatableField__WarningMessage {
    position: absolute;
    right: $spacing-medium;
    top: 0;
    height: 100%;

    svg {
      margin: auto 0;
      color: $warning;

      path:first-child {
        opacity: 0.1;
      }
    }
  }

  legend {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  fieldset {
    top: 0;
  }

  .MuiFormLabel-root.MuiFormLabel-root {
    transform: none;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
  }
}

.MRets__RepeatableField .RepeatableField__AddButton {
  display: flex;
  flex-direction: row;
  color: $green;

  svg {
    margin-right: $spacing-extra-small;
    fill: $green;
  }

  .RepeatableField__AddButton__Label {
    margin: auto;
    display: block;
  }
}

.RepeatableField__Item {
  display: flex;
  margin-bottom: $spacing-medium;
}

.RepeatableField__Item__RemoveButton {
  opacity: 0.2;
  margin: auto 0 auto $spacing-medium;

  svg {
    fill: $red;
  }
}

.RepeatableField__Item:hover .RepeatableField__Item__RemoveButton {
  opacity: 1;
}
