@import '../../../styles/variables';
@import '../../../styles/helpers';

@include applyThemes {
  .MainNavMenuItem {
    color: #fff;
    border: none;
  }

  .Sidebar a.MainNavMenuItem {
    color: #fff;
    border: none;
  }

  .Sidebar a.MainNavMenuItem:hover {
    background-color: $dark-gray;
  }

  .Sidebar a.MainNavMenuItem:focus {
    outline: none;
    box-shadow: none;
  }

  .Sidebar a.MainNavMenuItem.active {
    background-color: $white;
    color: $dark-gray;
    border: none;
  }
  .Sidebar a.MainNavMenuItem.active path {
    fill: $dark-gray;
  }
}
