// Converted Variables

// Custom Media Query Variables

/*
   NEGATIVE MARGINS

   Base:
     n = negative

   Modifiers:
     a = all
     t = top
     r = right
     b = bottom
     l = left

     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.na1 {
  margin: -#{$spacing-extra-small};
}
.na2 {
  margin: -#{$spacing-small};
}
.na3 {
  margin: -#{$spacing-medium};
}
.na4 {
  margin: -#{$spacing-large};
}
.na5 {
  margin: -#{$spacing-extra-large};
}
.na6 {
  margin: -#{$spacing-extra-extra-large-8};
}
.na7 {
  margin: -#{$spacing-extra-extra-large-16};
}

.nl1 {
  margin-left: -#{$spacing-extra-small};
}
.nl2 {
  margin-left: -#{$spacing-small};
}
.nl3 {
  margin-left: -#{$spacing-medium};
}
.nl4 {
  margin-left: -#{$spacing-large};
}
.nl5 {
  margin-left: -#{$spacing-extra-large};
}
.nl6 {
  margin-left: -#{$spacing-extra-extra-large-8};
}
.nl7 {
  margin-left: -#{$spacing-extra-extra-large-16};
}

.nr1 {
  margin-right: -#{$spacing-extra-small};
}
.nr2 {
  margin-right: -#{$spacing-small};
}
.nr3 {
  margin-right: -#{$spacing-medium};
}
.nr3-5 {
  margin-right: -#{$spacing-medium-2q};
}
.nr4 {
  margin-right: -#{$spacing-large};
}
.nr5 {
  margin-right: -#{$spacing-extra-large};
}
.nr6 {
  margin-right: -#{$spacing-extra-extra-large-8};
}
.nr7 {
  margin-right: -#{$spacing-extra-extra-large-16};
}

.nb1 {
  margin-bottom: -#{$spacing-extra-small};
}
.nb2 {
  margin-bottom: -#{$spacing-small};
}
.nb3 {
  margin-bottom: -#{$spacing-medium};
}
.nb3-5 {
  margin-bottom: -#{$spacing-medium-2q};
}
.nb4 {
  margin-bottom: -#{$spacing-large};
}
.nb5 {
  margin-bottom: -#{$spacing-extra-large};
}
.nb6 {
  margin-bottom: -#{$spacing-extra-extra-large-8};
}
.nb7 {
  margin-bottom: -#{$spacing-extra-extra-large-16};
}

.nt1 {
  margin-top: -#{$spacing-extra-small};
}
.nt2 {
  margin-top: -#{$spacing-small};
}
.nt3 {
  margin-top: -#{$spacing-medium};
}
.nt4 {
  margin-top: -#{$spacing-large};
}
.nt5 {
  margin-top: -#{$spacing-extra-large};
}
.nt6 {
  margin-top: -#{$spacing-extra-extra-large-8};
}
.nt7 {
  margin-top: -#{$spacing-extra-extra-large-16};
}

@media #{$breakpoint-not-small} {
  .na1-ns {
    margin: -#{$spacing-extra-small};
  }
  .na2-ns {
    margin: -#{$spacing-small};
  }
  .na3-ns {
    margin: -#{$spacing-medium};
  }
  .na4-ns {
    margin: -#{$spacing-large};
  }
  .na5-ns {
    margin: -#{$spacing-extra-large};
  }
  .na6-ns {
    margin: -#{$spacing-extra-extra-large-8};
  }
  .na7-ns {
    margin: -#{$spacing-extra-extra-large-16};
  }

  .nl1-ns {
    margin-left: -#{$spacing-extra-small};
  }
  .nl2-ns {
    margin-left: -#{$spacing-small};
  }
  .nl3-ns {
    margin-left: -#{$spacing-medium};
  }
  .nl4-ns {
    margin-left: -#{$spacing-large};
  }
  .nl5-ns {
    margin-left: -#{$spacing-extra-large};
  }
  .nl6-ns {
    margin-left: -#{$spacing-extra-extra-large-8};
  }
  .nl7-ns {
    margin-left: -#{$spacing-extra-extra-large-16};
  }

  .nr1-ns {
    margin-right: -#{$spacing-extra-small};
  }
  .nr2-ns {
    margin-right: -#{$spacing-small};
  }
  .nr3-ns {
    margin-right: -#{$spacing-medium};
  }
  .nr4-ns {
    margin-right: -#{$spacing-large};
  }
  .nr5-ns {
    margin-right: -#{$spacing-extra-large};
  }
  .nr6-ns {
    margin-right: -#{$spacing-extra-extra-large-8};
  }
  .nr7-ns {
    margin-right: -#{$spacing-extra-extra-large-16};
  }

  .nb1-ns {
    margin-bottom: -#{$spacing-extra-small};
  }
  .nb2-ns {
    margin-bottom: -#{$spacing-small};
  }
  .nb3-ns {
    margin-bottom: -#{$spacing-medium};
  }
  .nb4-ns {
    margin-bottom: -#{$spacing-large};
  }
  .nb5-ns {
    margin-bottom: -#{$spacing-extra-large};
  }
  .nb6-ns {
    margin-bottom: -#{$spacing-extra-extra-large-8};
  }
  .nb7-ns {
    margin-bottom: -#{$spacing-extra-extra-large-16};
  }

  .nt1-ns {
    margin-top: -#{$spacing-extra-small};
  }
  .nt2-ns {
    margin-top: -#{$spacing-small};
  }
  .nt3-ns {
    margin-top: -#{$spacing-medium};
  }
  .nt4-ns {
    margin-top: -#{$spacing-large};
  }
  .nt5-ns {
    margin-top: -#{$spacing-extra-large};
  }
  .nt6-ns {
    margin-top: -#{$spacing-extra-extra-large-8};
  }
  .nt7-ns {
    margin-top: -#{$spacing-extra-extra-large-16};
  }
}

@media #{$breakpoint-medium} {
  .na1-m {
    margin: -#{$spacing-extra-small};
  }
  .na2-m {
    margin: -#{$spacing-small};
  }
  .na3-m {
    margin: -#{$spacing-medium};
  }
  .na4-m {
    margin: -#{$spacing-large};
  }
  .na5-m {
    margin: -#{$spacing-extra-large};
  }
  .na6-m {
    margin: -#{$spacing-extra-extra-large-8};
  }
  .na7-m {
    margin: -#{$spacing-extra-extra-large-16};
  }

  .nl1-m {
    margin-left: -#{$spacing-extra-small};
  }
  .nl2-m {
    margin-left: -#{$spacing-small};
  }
  .nl3-m {
    margin-left: -#{$spacing-medium};
  }
  .nl4-m {
    margin-left: -#{$spacing-large};
  }
  .nl5-m {
    margin-left: -#{$spacing-extra-large};
  }
  .nl6-m {
    margin-left: -#{$spacing-extra-extra-large-8};
  }
  .nl7-m {
    margin-left: -#{$spacing-extra-extra-large-16};
  }

  .nr1-m {
    margin-right: -#{$spacing-extra-small};
  }
  .nr2-m {
    margin-right: -#{$spacing-small};
  }
  .nr3-m {
    margin-right: -#{$spacing-medium};
  }
  .nr4-m {
    margin-right: -#{$spacing-large};
  }
  .nr5-m {
    margin-right: -#{$spacing-extra-large};
  }
  .nr6-m {
    margin-right: -#{$spacing-extra-extra-large-8};
  }
  .nr7-m {
    margin-right: -#{$spacing-extra-extra-large-16};
  }

  .nb1-m {
    margin-bottom: -#{$spacing-extra-small};
  }
  .nb2-m {
    margin-bottom: -#{$spacing-small};
  }
  .nb3-m {
    margin-bottom: -#{$spacing-medium};
  }
  .nb4-m {
    margin-bottom: -#{$spacing-large};
  }
  .nb5-m {
    margin-bottom: -#{$spacing-extra-large};
  }
  .nb6-m {
    margin-bottom: -#{$spacing-extra-extra-large-8};
  }
  .nb7-m {
    margin-bottom: -#{$spacing-extra-extra-large-16};
  }

  .nt1-m {
    margin-top: -#{$spacing-extra-small};
  }
  .nt2-m {
    margin-top: -#{$spacing-small};
  }
  .nt3-m {
    margin-top: -#{$spacing-medium};
  }
  .nt4-m {
    margin-top: -#{$spacing-large};
  }
  .nt5-m {
    margin-top: -#{$spacing-extra-large};
  }
  .nt6-m {
    margin-top: -#{$spacing-extra-extra-large-8};
  }
  .nt7-m {
    margin-top: -#{$spacing-extra-extra-large-16};
  }
}

@media #{$breakpoint-large} {
  .na1-l {
    margin: -#{$spacing-extra-small};
  }
  .na2-l {
    margin: -#{$spacing-small};
  }
  .na3-l {
    margin: -#{$spacing-medium};
  }
  .na4-l {
    margin: -#{$spacing-large};
  }
  .na5-l {
    margin: -#{$spacing-extra-large};
  }
  .na6-l {
    margin: -#{$spacing-extra-extra-large-8};
  }
  .na7-l {
    margin: -#{$spacing-extra-extra-large-16};
  }

  .nl1-l {
    margin-left: -#{$spacing-extra-small};
  }
  .nl2-l {
    margin-left: -#{$spacing-small};
  }
  .nl3-l {
    margin-left: -#{$spacing-medium};
  }
  .nl4-l {
    margin-left: -#{$spacing-large};
  }
  .nl5-l {
    margin-left: -#{$spacing-extra-large};
  }
  .nl6-l {
    margin-left: -#{$spacing-extra-extra-large-8};
  }
  .nl7-l {
    margin-left: -#{$spacing-extra-extra-large-16};
  }

  .nr1-l {
    margin-right: -#{$spacing-extra-small};
  }
  .nr2-l {
    margin-right: -#{$spacing-small};
  }
  .nr3-l {
    margin-right: -#{$spacing-medium};
  }
  .nr4-l {
    margin-right: -#{$spacing-large};
  }
  .nr5-l {
    margin-right: -#{$spacing-extra-large};
  }
  .nr6-l {
    margin-right: -#{$spacing-extra-extra-large-8};
  }
  .nr7-l {
    margin-right: -#{$spacing-extra-extra-large-16};
  }

  .nb1-l {
    margin-bottom: -#{$spacing-extra-small};
  }
  .nb2-l {
    margin-bottom: -#{$spacing-small};
  }
  .nb3-l {
    margin-bottom: -#{$spacing-medium};
  }
  .nb4-l {
    margin-bottom: -#{$spacing-large};
  }
  .nb5-l {
    margin-bottom: -#{$spacing-extra-large};
  }
  .nb6-l {
    margin-bottom: -#{$spacing-extra-extra-large-8};
  }
  .nb7-l {
    margin-bottom: -#{$spacing-extra-extra-large-16};
  }

  .nt1-l {
    margin-top: -#{$spacing-extra-small};
  }
  .nt2-l {
    margin-top: -#{$spacing-small};
  }
  .nt3-l {
    margin-top: -#{$spacing-medium};
  }
  .nt4-l {
    margin-top: -#{$spacing-large};
  }
  .nt5-l {
    margin-top: -#{$spacing-extra-large};
  }
  .nt6-l {
    margin-top: -#{$spacing-extra-extra-large-8};
  }
  .nt7-l {
    margin-top: -#{$spacing-extra-extra-large-16};
  }
}
