@import '../../../styles/helpers';

.AdminSwitch {
  height: 26px;
  margin: 0 4px;
  padding: 0;
  width: 48px;

  .MuiSwitch-switchBase {
    bottom: 0;
  }

  .MuiSwitch-thumb {
    background-color: $white;
    border-color: transparent;
    border-radius: 3px;
    box-shadow: none;
    height: 20px;
    transform: translateX(-6px);
    width: 23px;
  }

  .Mui-checked .MuiSwitch-thumb {
    transform: translateX(-7px);
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiSwitch-track.MuiSwitch-track.MuiSwitch-track {
    background-color: $mid-gray;
    border-radius: 3px;
    opacity: 1;
  }

  .Mui-checked ~ .MuiSwitch-track.MuiSwitch-track {
    background-color: $green-lighter;
  }
}
